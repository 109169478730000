/* You can add global styles to this file, and also import other style files */
a { cursor: pointer }

.app-container {
    min-height: 320px;
    overflow: hidden;
}

.btn-delete-user {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}
@import "../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

body {
    background-color: #F3F2EF; /* LinkedIn's background color */
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif; /* LinkedIn's font */
    color: #333333; /* LinkedIn's primary text color */
    
    @media (max-width: 768px) { /* styles for devices with a viewport width up to 768px */
        font-size: 14px; /* smaller font size for mobile devices */
    }

    @media (min-width: 769px) { /* styles for devices with a viewport width of 769px or more */
        font-size: 16px; /* larger font size for desktop devices */
    }
}

.breadcrumb {
    background-color: #57324c; /* LinkedIn's primary color */
    border-radius: 0;
    padding: 5px 5px;
    // margin-bottom: 5px;
    font-size: 14px;
    font-weight: 800;
    color: #FFFFFF; /* White color for text to contrast with the primary color */
    box-shadow: none;
}
.breadcrumb-content {
    display: flex;
    align-items: center;
}
.breadcrumb-item {
    padding: 10px;
}
.breadcrumb-icon {
    margin-right: 10px;
    margin-left: 10px;
    color: #FFFFFF; /* White color for the icon to contrast with the primary color */
}

.breadcrumb-text {
    margin: 0;
    color: #FFFFFF; /* White color for text to contrast with the primary color */
}
.linkedin-navbar {
    background-color: #381f44; /* LinkedIn's primary color */
    // background-color: #4a1b53; /* LinkedIn's primary color */
    border: none;
    .nav-item {
        color: #FFFFFF; /* White color for text to contrast with the primary color */
        &:hover {
            color: #CACCCE; /* Lighter color for hover effect */
        }
    }
    .active {
        border-bottom: 2px solid #FFFFFF; /* White bottom border for active link */
    }
}
.user-info {
    padding-right: 20px; /* Add padding to the right end of the div */
}

.user-icon {
    margin-right: 5px;
    color: #FFFFFF; /* White color for the icon to contrast with the dark navbar */
    font-size: 20px; /* Increase the size of the icon */
}
.align-nav {
    align-items: center; /* align items vertically in the center */
    justify-content: start; /* align items horizontally at the start */
}
.linkedin-navbar, .breadcrumb {
    border-radius: 10px; /* Adjust this value to get the desired roundness */
}
.form-group {
    margin-bottom: 15px;
}

.form-select, .form-input {
    box-sizing: border-box; /* Include padding and border in total width and height */
    width: 100%;
    height: 42px; /* Explicitly set the height */
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
}
.ag-theme-balham {
    .ag-header-cell, .ag-header-group-cell {
        background-color: #f8f9fa;
        color: #495057;
        border-color: #ced4da;
    }
    .ag-row {
        color: #495057;
    }
    .ag-row.ag-row-even {
        background-color: #f8f9fa;
    }
    .ag-row.ag-row-odd {
        background-color: #ffffff;
    }
    .ag-cell-focus {
        border: none;
    }
    .ag-ltr {
        border: 1px solid #ced4da;
    }
}

.button {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:hover {
        color: #212529;
        background-color: #e9ecef;
        border-color: #dae0e5;
    }

    &:focus, &.active {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
    }

    &:disabled {
        opacity: .65;
    }
}
.h4 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #283e4a; /* LinkedIn's dark blue color */
    margin-bottom: 0.5rem;
    line-height: 1.2;
}

.form-input.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    &:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
}

.invalid-feedback {
    color: #dc3545;
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
}

.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    &:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
}
::ng-deep table {
    width: 100%;
    border-collapse: collapse;
}

::ng-deep table, ::ng-deep th,::ng-deep td {
    border: 1px solid #ddd;
    padding: 8px;
}

::ng-deep th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #7E9680;
    color: white;
}
.btn.btn-primary {
    background-color: #223024;
    color: white;
}
.btn.btn-secondary {
    background-color: #720406;
    color: white;
}
.btn.btn-info {
    background-color: #35283d;
    color: white;
}
.btn.btn-success {
    background-color: #5e4130;
    color: white;
}
.btn.btn-danger {
    background-color: #c2251a;
    color: white;
}
@keyframes drop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.logo {
    animation: drop 1s ease-out;
}